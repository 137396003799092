<template>
  <div id="TaskParticular">
    <nav>
      <p>
        <span style="cursor:pointer" @click="ToCourse">{{ $t('my_courses') }}</span>
        / 作业详情
      </p>
    </nav>
    <main>
      <div class="tittle">
        <p>作业点评</p>
      </div>
      <div class="content" v-html="Particular"></div>
    </main>
    <div class="tittle">
      <p>作业附件</p>
    </div>
    <div class="pic">
      <div v-for="item in content" :key="item.id">
        <el-image
            style="width: 100px; height: 200px;margin: 10px;"
            :src="item.submitUrl"
            :preview-src-list="imageArr"
        ></el-image>
        <!-- :preview-src-list="srcList" -->
        <!-- <img :src="item.submitUrl" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api';
import cookies from '@/utils/cookies';

export default {
  name: 'TaskParticular',
  data() {
    return {
      param: {
        token: '',
        id: '',
      },
      content: [],
      imageArr: [],
      Particular: '', // 作业点评
    };
  },
  created() {
    this.getTaskParticular();
  },
  methods: {
    // 跳转到课程页
    ToCourse() {
      this.$router.push('/Course/MyCourse');
    },
    getTaskParticular() {
      if (cookies.get('token')) {
        this.param.id = this.$route.query.id;
        this.param.token = cookies.get('token');
        API.TaskParticular(this.param)
            .then((res) => {
              if (res.message.success === true) {
                this.content = res.message.data.list;
                this.Particular = res.message.data.comment;
                this.content.map((item) => (this.imageArr.push(item.submitUrl)));
              }
            })
            .catch((mgs) => {
              console.log(mgs);
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tittle {
  width: 100px;
  padding: 0 10px;
  //text-align: center;
  border-left: 2px solid #f7b515;
  margin-top: 30px;
}
.content {
  margin-top: 30px;
  font-size: 13px;
}
.pic {
  display: flex;
  margin-top: 10px;
  img {
    width: 100px;
    height: 200px;
    margin: 10px;
  }
}
</style>
